import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTable from "app/components/SortableTable";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import {
  selectAllCompanyUsersById,
  selectGetAllUserStatus,
  selectUpdateUserByIdStatus,
  updateUserById,
} from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { useState } from "react";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { reInviteUser } from "../slice";
import DeactivateUserModal from "../Modals/DeactivateUserModal";
import Loading from "app/storybookComponents/Loading";
import { RowContent } from "app/components/SortableTable/types";
import { capitalizeWord } from "utils/helperFunctions";
import EditUserInfoModal from "../Modals/EditUserInfoModal";

interface Props {
  filteredUserAccountIds?: number[];
  searchTerm?: string;
  isLoading?: boolean;
  onInvitePeople?: () => void;
}

export default function PeopleTable({
  filteredUserAccountIds,
  searchTerm,
  isLoading,
  onInvitePeople,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ------------------------- App selectors ----------------------------------//
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const updateUserInfoStatus = useAppSelector(selectUpdateUserByIdStatus);
  const gettingAllUsersStatus = useAppSelector(selectGetAllUserStatus);

  // ------------------------- States ----------------------------------//
  const [showDeactivateUserModalUserId, setShowDeactivateUserModalUserId] =
    useState<null | number>(null);
  const [updateUserTo, setUpdateUserTo] = useState<null | {
    userAccountId: number;
    type: "Admin" | "User";
  }>(null);
  const [userToEditAccountId, setUserToEditAccountId] = useState(0);

  // ------------------------- Effects ----------------------------------//

  // ------------------------- Local variables ----------------------------------//
  const userIds = filteredUserAccountIds ?? Object.keys(usersInfoById);
  const totalPeopleInOrganization = Object.keys(usersInfoById).length;

  // ------------------------- Functions ----------------------------------//
  const onUserTableDropdownSelect = (e: string | null, id: number) => {
    switch (e) {
      case "deactivateUser": {
        return setShowDeactivateUserModalUserId(id);
      }
      case "resendInvitation": {
        return dispatch(reInviteUser(id));
      }
      case "seeUserGuide": {
        return navigate(`/UserGuide/${id}`);
      }
      case "editUserInfo": {
        return setUserToEditAccountId(id);
      }
    }
  };

  const getDropdown = (
    userAccountId: number,
    isAdmin?: boolean,
    isActive?: boolean
  ) => (
    <Dropdown
      onSelect={(e) => onUserTableDropdownSelect(e, userAccountId)}
      style={{ marginLeft: "auto" }}
    >
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {isActive ? (
          <Dropdown.Item eventKey="seeUserGuide">
            Go to User Guide
          </Dropdown.Item>
        ) : (
          <Dropdown.Item eventKey="resendInvitation">
            Resend Invitation
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Item eventKey="editUserInfo">
          Edit User Information
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="deactivateUser" className="danger">
          Deactivate User
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const rows: RowContent[] = [];

  userIds.forEach((userId) => {
    const userObject = usersInfoById[Number(userId)];
    if (!userObject) return;
    const {
      firstName,
      lastName,
      jobTitle,
      emailAddress,
      tmgRoleId,
      teamIds,
      userAccountId,
      firstTeamsLogin,
      gender: genderText,
    } = userObject;

    const isAdmin = tmgRoleId === 1;
    const isActive = !!firstTeamsLogin;
    const gender =
      genderText === "do-not-disclose" || !genderText
        ? "Not disclosed"
        : capitalizeWord(genderText);

    rows.push({
      className: !isActive ? "deactivated" : undefined,
      firstName: {
        displayValue: isActive ? (
          <Link to={`/UserGuide/${userAccountId}`}>{firstName}</Link>
        ) : (
          firstName
        ),
        sortValue: firstName,
      },
      lastName: {
        displayValue: isActive ? (
          <Link to={`/UserGuide/${userAccountId}`}>{lastName}</Link>
        ) : (
          lastName
        ),
        sortValue: lastName,
      },
      gender,
      jobTitle,
      emailAddress,
      teamCount: teamIds?.length,
      tmgRoleId: {
        displayValue: (
          <div className="row-gap-12px align-items-center">
            <div>{isAdmin ? "Admin" : "User"}</div>
            {getDropdown(userAccountId, isAdmin, isActive)}
          </div>
        ),
        sortValue: tmgRoleId || null,
      },
    });
  });

  const getWarningModalMessage = () => {
    if (updateUserTo === null) return "";

    if (updateUserTo.type === "User") {
      return "This user will no longer be able to access admin settings, change access permissions, and configure any global organization settings.";
    } else {
      return "Admins have the ability to create/delete teams, invite/remove members, change access permissions, and configure any global organization settings.";
    }
  };

  const onWarningModalConfirm = async () => {
    if (!updateUserTo) return;
    const tmgRoleId = updateUserTo.type === "Admin" ? 1 : 2;
    await dispatch(
      updateUserById({
        userAccountId: updateUserTo.userAccountId,
        payload: {
          tmgRoleId,
        },
      })
    );
    setUpdateUserTo(null);
  };

  const getWarningTitle = () => {
    if (!updateUserTo) return "";
    const memberInfo = usersInfoById[updateUserTo.userAccountId];
    const name =
      memberInfo?.firstName || memberInfo?.lastName
        ? `${memberInfo?.firstName ?? ""} ${memberInfo?.lastName ?? ""}`
        : memberInfo?.emailAddress;

    return `Are you sure you want to switch ${name} to "${
      updateUserTo?.type ?? ""
    }" ?`;
  };
  const getEmptyCard = () => {
    if (totalPeopleInOrganization === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px align-items-center">
            <span>
              <b>No teams have been created yet.</b>
            </span>
            <p>Teams created will be shown here</p>
            <Button onClick={() => onInvitePeople?.()}>
              <FontAwesomeIcon icon="plus" className="me-2" /> Create Team
            </Button>
          </div>
        </div>
      );
    }

    // if the length of rows is 0 then return the empty card
    if (rows.length === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px">
            <span>
              <b>{`No people found matching "${searchTerm}"`}</b>
            </span>
            <p>Try searching by name or email</p>
          </div>
        </div>
      );
    }

    return null;
  };

  if (isLoading || gettingAllUsersStatus === "loading") {
    return <Loading />;
  }

  return (
    <>
      {/* Special modal to make sure to reassign teams and departments before deleting the chose user if the user has any. */}
      <DeactivateUserModal
        show={showDeactivateUserModalUserId !== null}
        onHide={() => setShowDeactivateUserModalUserId(null)}
        userAccountId={showDeactivateUserModalUserId}
      />
      <EditUserInfoModal
        showModal={userToEditAccountId !== 0}
        hideModal={() => setUserToEditAccountId(0)}
        userAccountId={userToEditAccountId}
      />
      <WarningModal
        modalTitle="Change permissions"
        warningTitle={getWarningTitle()}
        warningMessage={getWarningModalMessage()}
        isOpen={!!updateUserTo}
        onConfirmClick={onWarningModalConfirm}
        hideModal={() => setUpdateUserTo(null)}
        isDanger={false}
        customButtonText="Confirm Changes"
        isInProgress={updateUserInfoStatus === "loading"}
      />
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={[
            {
              key: "firstName",
              label: "First Name",
            },
            {
              key: "lastName",
              label: "Last Name",
            },
            {
              key: "jobTitle",
              label: "Job Title",
            },
            {
              key: "emailAddress",
              label: "Email",
            },
            {
              key: "gender",
              label: "Gender",
            },
            {
              key: "teamCount",
              label: "# of Teams",
            },
            {
              key: "tmgRoleId",
              label: "Access Role",
            },
          ]}
        />
      </div>
      {getEmptyCard()}
    </>
  );
}
