//------------------ FontAwesome Library Import ------------------
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faCheck,
  faEllipsis,
  faLink,
  faPlus,
  faCheckCircle,
  faCircleXmark,
  faGear,
  faEnvelope,
  faXmark,
  faShare,
  faCircle,
  faArrowLeft,
  faCaretDown,
  faCaretUp,
  faUserPlus,
  faSearch,
  faDownload,
  faUpload,
  faSort,
  faSortUp,
  faSortDown,
  faThumbsUp,
  faThumbsDown,
  faUsers,
  faBell,
  faExternalLink,
  faBoltLightning,
  faGlasses,
  faSeedling,
  faBullseye,
  faBook,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faStar,
  faUser,
  faBell as fasBell,
  faArrowsRotate,
  faDiamond,
  faWrench,
  faTriangleExclamation as fafsTriangleExclamation,
  faMap,
  faSquarePollVertical,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHandshake,
  faDesktop,
  faUserTag,
  faBalanceScaleLeft,
  faBullseyeArrow,
  faCommentsAlt,
  faUsersClass,
  faQuoteLeft,
  faBookReader,
  faHandHoldingSeedling,
  faHandshakeAlt,
  faHouseUser,
  faDumbbell,
  faLightbulbOn,
  faBrain,
  faBatteryQuarter,
  faBadgeCheck,
  faMusic,
  faChartNetwork,
  faCoffee,
  faTrophy,
  faTrophyAlt,
  faPhoneLaptop,
  faBooks,
  faCameraMovie,
  faHandsHelping,
  faBatteryBolt,
  faObjectUngroup,
  faHands,
  faBookUser,
  faClock,
  faMonitorWaveform,
  faTriangleExclamation,
  faClipboardCheck,
  faInfo,
  faScaleUnbalanced,
  faQuestion,
  faUsers as faUsersLight,
  faFile,
  faBookmark as faBookmarkLight,
  faUser as faUserLight,
  faCommentTimes,
  faConstruction,
  faQuestionCircle as faQuestionCircleLight,
  faTreeAlt,
  faUsersMedical,
  faUserFriends,
  faClipboardListCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
  faDownload as farDownload,
  faQuestionCircle as farQuestionCircle,
  faClock as farClock,
  faCircleInfo,
  faTriangleExclamation as farTriangleExclamation,
  faStar as farStar,
  faCircleArrowUp as farCircleArrowUp,
  faCircleArrowDown as farCircleArrowDown,
  faLightbulbOn as farLightBulbOn,
  faBookmark,
  faCog,
  faArrowUp,
  faComment,
  faArrowDown,
  faEye,
  faEyeSlash,
  faArrowCircleRight,
  faTag as faTagRegular,
  faGear as faGearRegular,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faLockKeyhole,
  faRocketLaunch,
  faPollPeople,
  faMedal,
} from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/sharp-solid-svg-icons";

library.add(
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faArrowUp,
  faBadgeCheck,
  faBalanceScaleLeft,
  faBatteryBolt,
  faBatteryQuarter,
  faBell,
  faBoltLightning,
  faBook,
  faBookmark,
  faBookmarkLight,
  faBookReader,
  faBookReader,
  faBooks,
  faBookUser,
  faBrain,
  faBullseye,
  faBullseyeArrow,
  faCameraMovie,
  faCaretDown,
  faCaretUp,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleXmark,
  faClipboardCheck,
  faClipboardListCheck,
  faClock,
  faCoffee,
  faCog,
  faComment,
  faCommentsAlt,
  faCommentTimes,
  faConstruction,
  faDesktop,
  faDiamond,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faDumbbell,
  faEdit,
  faEllipsis,
  faEnvelope,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  fafsTriangleExclamation,
  faGear,
  faGearRegular,
  faGlasses,
  faHandHoldingSeedling,
  faHands,
  faHandshake,
  faHandshakeAlt,
  faHandsHelping,
  faHandsHelping,
  faHouseUser,
  faInfo,
  faLightbulbOn,
  faLink,
  faLocationDot,
  faLockKeyhole,
  faMap,
  faMedal,
  faMonitorWaveform,
  faMusic,
  faObjectUngroup,
  faPhoneLaptop,
  faPlus,
  faPollPeople,
  faQuestion,
  faQuestionCircleLight,
  faQuoteLeft,
  farCircleArrowDown,
  farCircleArrowUp,
  farClock,
  farDownload,
  farLightBulbOn,
  faRocketLaunch,
  farQuestionCircle,
  farStar,
  farTriangleExclamation,
  fasBell,
  faScaleUnbalanced,
  fasCheckCircle,
  faSearch,
  faSeedling,
  faShare,
  faSort,
  faSortDown,
  faSortUp,
  faSquarePollVertical,
  faStar,
  faTagRegular,
  faThumbsDown,
  faThumbsUp,
  faTreeAlt,
  faTriangleExclamation,
  faTrophy,
  faTrophyAlt,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserFriends,
  faUserLight,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUsersLight,
  faUsersMedical,
  faUserTag,
  faWrench,
  faXmark
);
