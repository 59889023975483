import { ICON_MAP } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTotalTimeToComplete } from "./helper";
import AlignIcon from "resources/icons/Align-Icon-Inverse.svg";
import TargetIcon from "resources/icons/Target-Icon-Inverse.svg";
import EmpowerIcon from "resources/icons/Empower-Icon-Inverse.svg";
import MonitorIcon from "resources/icons/Monitor-Icon-Inverse.svg";

interface Props {
  factor: "Target" | "Empower" | "Align" | "Monitor";
  questionCount: number;
}
export default function SectionDescription({
  factor,
  questionCount,
}: Readonly<Props>) {
  const getSectionIcon = () => {
    switch (factor) {
      case "Target":
        return <img src={TargetIcon} alt="Target" />;
      case "Empower":
        return <img src={EmpowerIcon} alt="Empower" />;
      case "Align":
        return <img src={AlignIcon} alt="Align" />;
      case "Monitor":
        return <img src={MonitorIcon} alt="Monitor" />;
    }
  };

  return (
    <>
      <div className={`team-360-section-description ${factor}`}>
        <div className="team-360-section-description-icon">
          {getSectionIcon()}
        </div>
        <div className="team-360-section-description-title">
          <span className="team-360-section-description-title-letter">
            {factor[0]}
          </span>
          <h1>{factor}</h1>
        </div>
        <ul>
          <li>
            <strong>What it is: </strong>
            {ICON_MAP[factor]["What it is"]}
          </li>
          <li>
            <strong>Why it matters: </strong>
            {ICON_MAP[factor]["Why it matters"]}
          </li>
          <li>
            <strong>What success looks like: </strong>
            {ICON_MAP[factor]["What success looks like"]}
          </li>
        </ul>
        <p>Answer based on your team's recent performance.</p>
      </div>
      <div className="time-and-question-count">
        <span>
          <div>
            <FontAwesomeIcon icon={["far", "question-circle"]} />{" "}
          </div>
          {questionCount} question{questionCount === 1 ? "" : "s"}
        </span>
        <span>
          <div>
            <FontAwesomeIcon icon={["far", "clock"]} />{" "}
          </div>
          {getTotalTimeToComplete(questionCount)}
        </span>
      </div>
    </>
  );
}
