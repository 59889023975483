import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import Button from "app/storybookComponents/Button";
import { SURVEY_MAP } from "./constants";
import { LaunchAssessmentOverviewItems } from "./types";
import { useNavigate } from "react-router-dom";

interface Props {
  onLaunchAssessment: () => void;
  assessmentId: number;
  scheduledBy?: "admin" | "teamLeader" | "departmentLeader";
  leftButton?: {
    text: string;
    onClick: () => void;
  };
  onCloseModal?: () => void;
}

export default function LaunchAssessmentOverview({
  assessmentId,
  onLaunchAssessment,
  scheduledBy = "teamLeader",
  leftButton,
  onCloseModal,
}: Props) {
  const navigate = useNavigate();

  const { overviewBodyItemsByRole, headerDescription } =
    SURVEY_MAP[assessmentId];

  const getTabContent = () => {
    if (overviewBodyItemsByRole?.[scheduledBy]) {
      return getTabByBodyItem(overviewBodyItemsByRole[scheduledBy]);
    }
    return null;
  };

  const getTabByBodyItem = ({
    listItems: overviewBodyItems,
    supportingText,
  }: LaunchAssessmentOverviewItems) => {
    const listItems: ReactNode[] = [];
    overviewBodyItems.forEach((item) => {
      listItems.push(
        <li className="row-gap-8px" key={item.title}>
          <FontAwesomeIcon
            icon="circle-check"
            className="mt-1"
            style={{ color: "#001F4E" }}
          />
          <div className="column-gap-4px">
            <p>
              <b>{item.title}</b>
            </p>
            <p>{item.text}</p>
          </div>
        </li>
      );
    });
    listItems.push(
      <li className="row-gap-8px" key={"supportingText"}>
        <FontAwesomeIcon
          icon={["far", "circle-arrow-right"]}
          className="mt-1"
          style={{ color: "#001F4E" }}
        />
        <div className="column-gap-4px">
          <p>
            <b>Start Today</b>
          </p>
          <p>{supportingText}</p>
        </div>
      </li>
    );
    return (
      <ul className="bulletless-list mb-0 column-gap-12px">{listItems}</ul>
    );
  };

  return (
    <>
      {headerDescription ? <p>{headerDescription}</p> : null}
      <hr className="m-0" />
      {getTabContent()}
      <hr className="m-0" />
      <div className="d-flex justify-content-between">
        {leftButton ? (
          <Button onClick={leftButton.onClick} variant="secondary-gray">
            {leftButton.text}
          </Button>
        ) : null}
        <div className="ms-auto row-gap-12px">
          <Button
            onClick={() => {
              navigate("/survey/team360?eventId=sample");
              onCloseModal?.();
            }}
            variant="secondary-blue"
          >
            Preview TEAMscan survey
          </Button>
          {/* <Button variant="secondary-blue">See sample report</Button> */}
          <Button onClick={onLaunchAssessment}>
            Next <FontAwesomeIcon icon={"arrow-right"} className="ms-2" />
          </Button>
        </div>
      </div>
    </>
  );
}
