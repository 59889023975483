import {
  ALL_TEAM_360_FACTORS,
  TEAM360_DIMENSIONS_MAP,
  TEAM_360_SURVEY_STATES,
  TEAM_360_EVENT_FREQUENCY,
  TEAM_360_EVENT_TYPES,
  TEAM_360_KEEP_ASSESSMENT_OPEN_OPTIONS,
} from "./constants";

export type TTeam360Factor = (typeof ALL_TEAM_360_FACTORS)[number];
export const isTeam360Factor = (value: string): value is TTeam360Factor => {
  return ALL_TEAM_360_FACTORS.includes(value as TTeam360Factor);
};
export type TTeam360Dimension = {
  [K in keyof typeof TEAM360_DIMENSIONS_MAP]: (typeof TEAM360_DIMENSIONS_MAP)[K][number];
}[keyof typeof TEAM360_DIMENSIONS_MAP];
export const isTeam360Dimension = (
  value: string
): value is TTeam360Dimension => {
  return Object.keys(TEAM360_DIMENSIONS_MAP).includes(value);
};

export type SurveyStatus = {
  teamName: string;
  scheduledBy: string;
  teamAvatarUrl: string | null;
  startDate: string;
  endDate: string;
  assessmentJWT: string;
  error?: string;
};

export type TTeam360FactorResponse = {
  factor: string;
  item: string;
  id: number;
};

export type Team360AssessmentResult = {
  dimensions: {
    [dimensionName: string]: number;
  } | null;
  factors: {
    [factorName: string]: number;
  } | null;
  overall: number | null;
};

export type Team360AssessmentResultsResponse = {
  teamScores: Team360AssessmentResult;
  userScores: Team360AssessmentResult;
};

export type AllTeam360AssessmentResults = {
  companyScores: Team360AssessmentResult;
  departmentScores: { [departmentId: number]: Team360AssessmentResult };
  teamScores: { [teamId: number]: Team360AssessmentResult };
};

export type Team360FeedbackPayload = {
  teamEffectivenessRating: number;
  likelihoodToLeaveThisTeam: number;
  primaryWorkStyle: number;
  assessmentExperienceRating?: number;
  assessmentFeedback?: string;
  doMoreResponse?: string;
  doDifferentlyResponse?: string;
};

export type Team360StoredResponses = {
  [factorName in TTeam360Factor]: {
    [itemId: number]: {
      answer: number;
      timeTaken: number;
    };
  };
};

export type getPreviousAnswersResponse = {
  factorName: TTeam360Factor;
  itemId: number;
  answer: number;
  timeTaken: number;
}[];

export type Team360AssessmentState = (typeof TEAM_360_SURVEY_STATES)[number];

export type SurveyEventFrequency =
  (typeof TEAM_360_EVENT_FREQUENCY)[number]["label"];
export type SurveyEventTypes = (typeof TEAM_360_EVENT_TYPES)[number]["label"];
export type SurveyKeepAssessmentOpenOption =
  (typeof TEAM_360_KEEP_ASSESSMENT_OPEN_OPTIONS)[number]["label"];

export type AgreeablenessResult = {
  factor?: string;
  dimensionName: string;
  dimension: string;
  comparedScore: number;
  userScore: number;
};
export type AgreementState = {
  "Low Agreement": AgreeablenessResult[] | null;
  "Medium Agreement": AgreeablenessResult[] | null;
  "High Agreement": AgreeablenessResult[] | null;
};

export type TeamScanStatusError =
  | null
  | "invalidSurvey"
  | "pastDue"
  | "completed";
