import SideDrawer from "app/components/SideDrawer/SideDrawer";
import QuickActionCard from "./QuickActionCard";
import { ValidDashboardRole } from "app/containers/Dashboard/types";
import { useAppDispatch } from "utils/redux/hooks";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
} from "app/components/Modals/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForOrganization,
  showScheduleAssessmentModalForDepartmentId,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";

interface Props {
  showing?: boolean;
  onHide: () => void;
  role?: ValidDashboardRole;
  teamId?: number | null;
  departmentId?: number | null;
  onShowInviteMemberModal?: () => void;
  onShowInviteTeamLeaderModal?: () => void;
}

export default function QuickActionDrawer({
  showing,
  onHide,
  role,
  departmentId,
  onShowInviteMemberModal,
  onShowInviteTeamLeaderModal,
  teamId,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  if (role === "Team Member") return null;

  const getDrawerBody = () => {
    switch (role) {
      case "Team Leader / Team Member":
        return (
          <>
            <QuickActionCard
              title="Launch TEAMscan"
              description="Launch the survey for your entire team."
              actionButton={{
                onClick: () => {
                  if (teamId) {
                    dispatch(showScheduleAssessmentModalForTeamId(teamId));
                  } else {
                    dispatch(showScheduleAssessmentModal());
                  }
                },
                text: "Launch TEAMscan",
              }}
              iconName="rocket-launch"
            />
            <QuickActionCard
              title="Invite team members"
              description="Invite team members to set up their user guides and take part in TEAMscan surveys for their teams."
              actionButton={{
                onClick: () => {
                  onShowInviteMemberModal?.();
                },
                text: "Invite team member",
              }}
              iconPrefix="fas"
              iconName="user-plus"
            />
            <QuickActionCard
              title="Create a new team"
              description="Name your new team, invite tem members, and set up your team guide."
              actionButton={{
                onClick: () => dispatch(openCreateTeamModal()),
                text: "Create a team",
              }}
              iconPrefix="fas"
              iconName="poll-people"
            />
          </>
        );
      case "Department Leader":
        return (
          <>
            <QuickActionCard
              title="Launch TEAMscan"
              description="Launch the survey for your entire department or specific teams in your department."
              actionButton={{
                onClick: () => {
                  if (departmentId) {
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(departmentId)
                    );
                  }
                },
                text: "Launch TEAMscan",
              }}
              iconName="rocket-launch"
            />

            <QuickActionCard
              title="Create a team"
              description="Create a team of your direct reports and assign yourself or another teammate as team leader. Suggestion: Only create teams you directly manage."
              actionButton={{
                onClick: () =>
                  dispatch(
                    openCreateTeamModal({
                      preSelectedDepartmentId: departmentId,
                    })
                  ),
                text: "Create a team",
              }}
              iconPrefix="fas"
              iconName="poll-people"
            />
            <QuickActionCard
              title="Invite team leaders"
              description="Invite team leaders to set up their team, invite team members, and create their team guide."
              actionButton={{
                onClick: () => {
                  onShowInviteTeamLeaderModal?.();
                },
                text: "Invite leader",
              }}
              iconPrefix="fas"
              iconName="user-plus"
            />
            <QuickActionCard
              title="Invite team members"
              description="Invite team members to set up their user guides and take part in TEAMscan surveys for their teams."
              actionButton={{
                onClick: () => {
                  onShowInviteMemberModal?.();
                },
                text: "Invite new member",
              }}
              iconPrefix="fas"
              iconName="user-plus"
            />
          </>
        );
      case "Admin":
        return (
          <>
            <QuickActionCard
              title="Launch TEAMscan"
              description="Launch the survey for a single team, one or more departments, or your entire organization."
              actionButton={{
                onClick: () => {
                  dispatch(showScheduleAssessmentModalForOrganization());
                },
                text: "Launch TEAMscan",
              }}
              iconName="rocket-launch"
            />
            <QuickActionCard
              title="Set up departments"
              description="Set up your departments structure to enable easier onboarding and analytics."
              actionButton={{
                onClick: () => {
                  dispatch(openEditDepartmentModal());
                },
                text: "Create Departments",
              }}
              iconPrefix="fas"
              iconName="wrench"
              listSpan="Full Roll Out"
            />
            <QuickActionCard
              title="Create a team"
              description="Create a team of your direct reports and assign yourself or another teammate as team leader. Suggestion: Only create teams you directly manage."
              actionButton={{
                onClick: () => dispatch(openCreateTeamModal()),
                text: "Create a team",
              }}
              iconPrefix="fas"
              iconName="poll-people"
            />
            <QuickActionCard
              title="Invite team members"
              description="Invite team members to set up their user guides and take part in TEAMscan surveys for their teams."
              actionButton={{
                onClick: () => {
                  onShowInviteMemberModal?.();
                },
                text: "Invite new member",
              }}
              iconPrefix="fas"
              iconName="user-plus"
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <SideDrawer
      show={!!showing}
      onHide={onHide}
      title="Quick Actions"
      className="quick-actions"
    >
      {getDrawerBody()}
    </SideDrawer>
  );
}
