import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { AssessmentMap } from "app/containers/Assessment/constants";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import ProgressBar from "app/storybookComponents/LikertAssessment/ProgressBar";
import { selectPendingAssessments } from "app/containers/Assessment/slice";
import SingleLineLikertAssessment from "app/storybookComponents/LikertAssessment/SingleLineLikertAssessment";
import LikertScale from "app/storybookComponents/LikertAssessment/LikertScale";
import TextArea from "app/storybookComponents/Forms/TextArea";
import {
  LIKERT_SCALE_ALT_1,
  LIKERT_SCALE_LIKELIHOOD,
  LIKERT_SCALE_WORK_STYLE,
} from "utils/constants";
import NoResultsIcon from "resources/images/no-results-x.png";
import { useSearchParams } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { TTeam360Factor, Team360AssessmentState } from "./types";
import {
  selectTeam360FeedbackPayload,
  select360AssessmentFactors,
  selectStoredResponses,
  selectSurveyProgress,
  setStoredResponses,
  setSurveyProgress,
  setTeam360FeedbackPayload,
  send360AssessmentResults,
  selectActiveAssessmentEventId,
  setActiveAssessmentEventId,
  increaseCurrentQuestionIndex,
  setCurrentQuestionIndexToLastAnswered,
  selectCurrentQuestionIndex,
  decreaseCurrentQuestionIndex,
  setSurveysTeamId,
  selectPreviousAnswers,
  selectGetPreviousAnswersStatus,
  getSurveyScanStatus,
  selectGetSurveyScanStatus,
  selectSurveyScanStatus,
  selectGetSurveyScanStatusError,
  setTeamScanSurveyStatusError,
  send360TeamFeedback,
  resetTeam360FeedbackPayload,
  selectFeedbackSentStatus,
} from "./slice";
import { ICON_MAP } from "./constants";
import SectionDescription from "./Team360AssessmentModal/SectionDescription";
import Overview from "./Team360AssessmentModal/Overview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectGetAllUserStatus,
  selectGetUserStatus,
} from "app/containers/Global/slice";
import Loading from "app/storybookComponents/Loading";
import SmileyFeedbackForm from "../SmileyFeedbackForm/SmileyFeedbackForm";
interface Props {
  showWarningModal: () => void;
  onClose: () => void;
  isSampleAssessment?: boolean;
  closeWithOutWarning?: () => void;
}

export default function Team360Assessment({
  showWarningModal,
  onClose,
  isSampleAssessment,
  closeWithOutWarning,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const teamFeedback = useAppSelector(selectTeam360FeedbackPayload);
  const storedResponses = useAppSelector(selectStoredResponses);
  const team360Factors = useAppSelector(select360AssessmentFactors);
  const progress = useAppSelector(selectSurveyProgress);
  const activeAssessmentId = useAppSelector(selectActiveAssessmentEventId);
  const questionIndex = useAppSelector(selectCurrentQuestionIndex);
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const previousAnswers = useAppSelector(selectPreviousAnswers);
  const getPreviousAnswersStatus = useAppSelector(
    selectGetPreviousAnswersStatus
  );
  const getLoggedInUserStatus = useAppSelector(selectGetUserStatus);
  const getAllUserStatus = useAppSelector(selectGetAllUserStatus);
  const surveyScanStatus = useAppSelector(selectSurveyScanStatus);
  const getSurveyScanStatusStatus = useAppSelector(selectGetSurveyScanStatus);
  const getSurveyScanStatusError = useAppSelector(
    selectGetSurveyScanStatusError
  );
  const feedbackSentStatus = useAppSelector(selectFeedbackSentStatus);

  // ------------------------ Memo Constants -------------------------- //
  const currentAssessment = useMemo(
    () =>
      activeAssessmentId
        ? pendingAssessments[activeAssessmentId] ?? surveyScanStatus
        : null,
    [pendingAssessments, activeAssessmentId, surveyScanStatus]
  );

  const currentUser = useMemo(() => {
    return userAccountId ? usersById[userAccountId] : null;
  }, [userAccountId, usersById]);

  const isUserPartOfTeam = useMemo(() => {
    if (!currentAssessment?.teamId || !currentUser) {
      return false;
    }

    return currentUser.teamIds?.includes(currentAssessment.teamId);
  }, [currentUser, currentAssessment]);

  // ------------------------ useState -------------------------- //
  const [activeFactor, setActiveFactor] = useState<TTeam360Factor>("Target");
  const [state, setState] = useState<Team360AssessmentState>("Overview");
  const [isFeedbackFormShowing, setIsFeedbackFormShowing] = useState(false);

  // ------------------------ refs -------------------------- //
  const stateRef = useRef(state);
  stateRef.current = state;

  // ------------------------ useEffect -------------------------- //

  const onSubmitResults = useCallback(() => {
    const completedAssessment =
      stateRef.current === "Team Feedback 1" ||
      stateRef.current === "Team Feedback 2"
        ? 1
        : 0;

    dispatch(send360AssessmentResults({ completedAssessment }));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("beforeunload", onSubmitResults);
    return () => {
      window.removeEventListener("beforeunload", onSubmitResults);
    };
  }, [onSubmitResults]);

  useEffect(() => {
    return () => {
      onSubmitResults();
    };
  }, [onSubmitResults]);

  useEffect(() => {
    if (activeAssessmentId) {
      return;
    }

    // If no activeAssessmentId then we check the query params to see if there is an eventId.
    const queryEventId = searchParams.get("eventId");
    if (queryEventId && queryEventId !== "sample") {
      dispatch(setActiveAssessmentEventId(queryEventId));
      // get any stored responses from the backend.
      dispatch(getSurveyScanStatus(queryEventId));
    } else if (!queryEventId) {
      dispatch(setTeamScanSurveyStatusError("invalidSurvey"));
    }

    const teamId = searchParams.get("teamId");
    if (teamId) {
      dispatch(setSurveysTeamId(parseInt(teamId)));
    }
  }, [activeAssessmentId, searchParams, dispatch]);

  useEffect(() => {
    // if it is a sample assessment then we want to set the state to the section description.
    if (isSampleAssessment) {
      setState("Section Description");
      setActiveFactor("Target");
    }
  }, [isSampleAssessment]);

  // ------------------------ memo constants -------------------------- //
  const questionSet = useMemo(() => {
    if (isSampleAssessment) {
      // if sample assessment then we will want to only have one question per factor.
      const copy = { ...team360Factors };
      Object.keys(copy).forEach((factor) => {
        copy[factor] = [copy[factor][0]];
      });
      return copy;
    }

    return team360Factors;
  }, [isSampleAssessment, team360Factors]);

  // ------------------------ handler functions -------------------------- //
  const onSubmitFeedback = async () => {
    await dispatch(send360TeamFeedback());
    dispatch(resetTeam360FeedbackPayload());
    dispatch(setTeam360FeedbackPayload({}));
    dispatch(setActiveAssessmentEventId(null));
    dispatch(setSurveysTeamId(null));
  };

  const getNumberOfStoredResponses = () => {
    const targetLength = Object.keys(storedResponses.Target).length;
    const empowerLength = Object.keys(storedResponses.Empower).length;
    const alignLength = Object.keys(storedResponses.Align).length;
    const monitorLength = Object.keys(storedResponses.Monitor).length;

    return targetLength + empowerLength + alignLength + monitorLength;
  };

  const handleNextClick = () => {
    // If the user clicks on the next while the user is inside of the section description, we want to go to the assessment.
    switch (state) {
      case "Overview":
        if (
          previousAnswers &&
          // making this check because if a user resumes a assessment, answers more questions,
          // goes back to the overview, and then hits next, they shouldn't skip to the last answered question
          previousAnswers.length === getNumberOfStoredResponses()
        ) {
          setState("Assessment");
          const factorOfLastAnsweredQuestion =
            previousAnswers[previousAnswers.length - 1].factorName;
          setActiveFactor(factorOfLastAnsweredQuestion);
          dispatch(setCurrentQuestionIndexToLastAnswered());
          return;
        }
        return setState("Section Description");
      case "Section Description":
        return setState("Assessment");
      case "Team Feedback 1": {
        setState("Team Feedback 2");
        dispatch(setSurveyProgress(99));
        return;
      }
      case "Team Feedback 2": {
        // TODO: if it is a sample assessment then we would just want to end the assessment and open the modal that the user had opened before.
        if (isSampleAssessment) {
          return closeWithOutWarning?.();
        }

        dispatch(
          send360AssessmentResults({
            completedAssessment: 1,
          })
        );

        setState("Success!");
        return;
      }
      default:
        break;
    }

    // Given that we are already in the assessment and the user is clicking next to proceed to the next section change the active factor.
    switch (activeFactor) {
      case "Monitor":
        // If we are on the last section, we will want to return so that we don't set the state to Section Description.
        return setState("Team Feedback 1");
      case "Target":
        setActiveFactor("Empower");
        break;
      case "Empower":
        setActiveFactor("Align");
        break;
      case "Align":
        setActiveFactor("Monitor");
        break;
      default: {
        break;
      }
    }

    // If we are not on the last assessment, we want to go to the next assessment description.
    setState("Section Description");
  };

  const handleBackButton = () => {
    if (state === "Overview") {
      // Instead of navigating to the previous page we need to show the warning modal.
      return showWarningModal();
    }

    // We might need to add some extra logic incase the user is inside of an assessment, we go back to the previous question instead of going back to the previous question.
    if (state === "Assessment") {
      return setState("Section Description");
    }

    if (state === "Section Description") {
      switch (activeFactor) {
        case "Target":
          // Instead of navigating to the previous page we need to show the warning modal.
          return setState("Overview");
        case "Empower":
          setActiveFactor("Target");
          break;
        case "Align":
          setActiveFactor("Empower");
          break;
        case "Monitor":
          setActiveFactor("Align");
      }
      dispatch(decreaseCurrentQuestionIndex());
      setState("Assessment");
    }

    if (state === "Team Feedback 1") {
      dispatch(decreaseCurrentQuestionIndex());
      setState("Assessment");
    }

    if (state === "Team Feedback 2") {
      setState("Team Feedback 1");
    }
  };

  const getProgressBar = () => {
    if (state === "Overview" || state === "Success!") {
      return null;
    }

    return (
      <div className={`progress-bar-container column-gap-8px`}>
        <div className="progress-bar-description">
          <span>
            {state !== "Team Feedback 1" && state !== "Team Feedback 2"
              ? `Section ${ICON_MAP[activeFactor].sequence} of 4: ${activeFactor}`
              : `Reflection`}
          </span>
          <span>{Math.round(progress)}% Complete</span>
        </div>
        <ProgressBar fillWidth={progress} />
      </div>
    );
  };

  // This function will return the question number with respect to the factor.
  const getQuestionNumber = () => {
    let questionIndexCopy = questionIndex;
    Object.entries(questionSet).some(([factorName, factorObj]) => {
      if (factorName === activeFactor) {
        return true;
      }
      questionIndexCopy -= factorObj.length;
      return false;
    });
    return questionIndexCopy;
  };

  const getMemberIds = (): number[] => {
    // We need to get the memberIds from userAccountIds and teamIds
    if (currentAssessment?.userAccountIds) {
      return [...currentAssessment.userAccountIds];
    } else if (currentAssessment?.userAccountId) {
      return [currentAssessment.userAccountId];
    }

    // If all fails return an empty array
    return [];
  };

  const getFeedbackForm = () => {
    return (
      <div className="column-gap-24px">
        <div>
          <p>
            <b>
              Overall, how would you rate your experience during this assessment
              process?
            </b>
          </p>
          <p className="grey-text">
            (Responses are anonymous and won’t impact your test results in any
            way)
          </p>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <SmileyFeedbackForm
              smileySelected={
                teamFeedback.assessmentExperienceRating ?? undefined
              }
              onSelectSmiley={(smileyValue) => {
                const newValue =
                  teamFeedback.assessmentExperienceRating === smileyValue
                    ? undefined
                    : smileyValue;

                dispatch(
                  setTeam360FeedbackPayload({
                    ...teamFeedback,
                    assessmentExperienceRating: newValue,
                  })
                );
              }}
            />
          </div>

          <Collapse
            in={
              teamFeedback.assessmentExperienceRating !== null &&
              teamFeedback.assessmentExperienceRating !== undefined
            }
          >
            <div className="mt-3">
              <div className="column-gap-4px mb-1">
                <p>
                  <b>(Optional) Please give us feedback:</b>
                </p>
                <p className="grey-text">
                  Is there any changes that would improve your experience?
                </p>
              </div>
              <TextArea
                inputText={teamFeedback?.assessmentFeedback || ""}
                onTextChange={(text) => {
                  dispatch(
                    setTeam360FeedbackPayload({
                      ...teamFeedback,
                      assessmentFeedback: text,
                    })
                  );
                }}
                inputLabel=""
                controlId="module-title"
              />
            </div>
          </Collapse>
        </div>
      </div>
    );
  };

  const getBodyContent = () => {
    const questionCount = questionSet[activeFactor]?.length;
    switch (state) {
      case "Overview": {
        return (
          <Overview
            sentToText={currentAssessment?.sentToText}
            endDate={currentAssessment?.endDate}
            startDate={currentAssessment?.startDate}
            frequency={currentAssessment?.frequency}
            frequencyText={currentAssessment?.frequencyText}
            memberIds={getMemberIds()}
            teamId={currentAssessment?.teamId}
            onStartSurvey={() => {
              setState("Section Description");
              setActiveFactor("Target");
            }}
            teamName={surveyScanStatus?.teamName}
            teamProfilePicture={surveyScanStatus?.teamAvatarUrl}
            leaderName={surveyScanStatus?.scheduledBy}
          />
        );
      }
      case "Section Description":
        return (
          <SectionDescription
            factor={activeFactor}
            questionCount={questionCount}
          />
        );
      case "Assessment": {
        const questionNumberWithRespectToFactor = getQuestionNumber();
        return (
          <SingleLineLikertAssessment
            questions={questionSet[activeFactor] || []}
            onComplete={() => {
              handleNextClick();
            }}
            storeResponses={(itemId, answer) => {
              dispatch(
                setStoredResponses({
                  factorName: activeFactor,
                  itemId,
                  ...answer,
                })
              );
            }}
            storedResponses={storedResponses[activeFactor]}
            onFirstBackButtonPress={handleBackButton}
            questionNumber={questionNumberWithRespectToFactor}
            onBackButtonPress={() => {
              dispatch(decreaseCurrentQuestionIndex());
            }}
            onForwardButtonPress={() => {
              dispatch(increaseCurrentQuestionIndex());
            }}
          />
        );
      }
      case "Team Feedback 1":
        return (
          <>
            <LikertScale
              questionTitle="How would you rate the overall performance of this team?"
              onSelect={(answer) => {
                dispatch(
                  setTeam360FeedbackPayload({
                    ...teamFeedback,
                    teamEffectivenessRating: answer,
                  })
                );
              }}
              selectedValue={teamFeedback.teamEffectivenessRating}
              likertScale={LIKERT_SCALE_ALT_1}
            />
            <LikertScale
              questionTitle="How likely are you to look for chances to leave this team in the next 6-12 months?"
              onSelect={(answer) => {
                dispatch(
                  setTeam360FeedbackPayload({
                    ...teamFeedback,
                    likelihoodToLeaveThisTeam: answer,
                  })
                );
              }}
              selectedValue={teamFeedback.likelihoodToLeaveThisTeam}
              likertScale={LIKERT_SCALE_LIKELIHOOD}
            />
            <LikertScale
              questionTitle="How do you primarily work on this team?"
              onSelect={(answer) => {
                dispatch(
                  setTeam360FeedbackPayload({
                    ...teamFeedback,
                    primaryWorkStyle: answer,
                  })
                );
              }}
              selectedValue={teamFeedback.primaryWorkStyle}
              likertScale={LIKERT_SCALE_WORK_STYLE}
            />
          </>
        );
      case "Team Feedback 2":
        return (
          <>
            <div className="snapshot-box">
              <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
              <p>
                Answering these two reflection questions are <b>optional</b>.
                Any comments you submit here will not be affiliated with your
                name, but will be shared with your team leader and team members.
              </p>
            </div>
            <TextArea
              maxLength={250}
              inputLabel="Is there anything you would like to see your team or team leader keep doing or do more of? (Optional)"
              controlId={"doMoreResponse"}
              onTextChange={(text) => {
                dispatch(
                  setTeam360FeedbackPayload({
                    ...teamFeedback,
                    doMoreResponse: text,
                  })
                );
              }}
              inputText={teamFeedback.doMoreResponse || ""}
              placeholder="Field Placeholder"
            />
            <TextArea
              maxLength={250}
              inputLabel="Is there anything you would like to see your team or team leader do differently or stop doing? (Optional)"
              controlId={"doDifferentlyResponse"}
              onTextChange={(text) => {
                dispatch(
                  setTeam360FeedbackPayload({
                    ...teamFeedback,
                    doDifferentlyResponse: text,
                  })
                );
              }}
              placeholder="Field Placeholder"
              inputText={teamFeedback.doDifferentlyResponse ?? ""}
            />
          </>
        );
      case "Success!": {
        return (
          <div className="column-gap-20px text-center align-items-center">
            <div className="column-gap-16px" style={{ marginTop: `20px` }}>
              <FontAwesomeIcon
                icon={"check-circle"}
                style={{ color: "#009952", fontSize: "40px" }}
              />
              <p>
                <b>Thanks for completing the TEAMscan!</b>
              </p>
              <p style={{ maxWidth: `560px` }}>
                You've successfully completed the TEAMscan survey. This survey
                helps you understand yourself and your team better. To dig
                deeper and see insights, view results.
              </p>
            </div>
            <hr className="m-0 w-100" />
            {isFeedbackFormShowing ? (
              getFeedbackForm()
            ) : (
              <p style={{ maxWidth: `560px` }}>
                <b>Had any issues with the survey?</b> Feel free to give
                feedback by clicking below. We greatly appreciate your feedback.
              </p>
            )}
            <hr className="m-0 w-100" />
            <div className="row-gap-16px" style={{ margin: "16px 0px" }}>
              {isFeedbackFormShowing ? (
                <Button
                  onClick={() => {
                    onSubmitFeedback();
                  }}
                  disabled={feedbackSentStatus === "loading"}
                >
                  Submit and view results
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      closeWithOutWarning?.();
                    }}
                  >
                    View results
                  </Button>
                  <Button
                    variant="secondary-blue"
                    onClick={() => {
                      setIsFeedbackFormShowing(true);
                    }}
                  >
                    Give Feedback
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };

  const isNextDisabled = () => {
    if (getPreviousAnswersStatus === "loading") {
      return true;
    }

    // If we are in the 'Team Feedback 1' state and the user has not answered all of the questions, we want to disable the next button.
    if (state === "Team Feedback 1") {
      return (
        !teamFeedback.teamEffectivenessRating ||
        !teamFeedback.likelihoodToLeaveThisTeam ||
        !teamFeedback.primaryWorkStyle
      );
    }

    return false;
  };

  const getNextButtonText = () => {
    if (state === "Team Feedback 2") {
      return isSampleAssessment ? "Exit Preview" : "Finish and submit";
    }

    if (state !== "Overview") {
      return "Next Question";
    }

    // Given that we are checking if the state is overview, we want to check if the user has already started the survey.
    if (
      previousAnswers &&
      previousAnswers.length > 0 &&
      getPreviousAnswersStatus !== "loading"
    ) {
      return "Resume Survey";
    }

    return "Start Survey";
  };

  const getAutoJoinTeamSnapshot = () => {
    // If its the sample guide then we don't want to show the snapshot box.
    if (isSampleAssessment) {
      return null;
    }
    // If we are not in the overview state then we don't want to show the snapshot box.
    if (state !== "Overview") {
      return null;
    }
    // If the user is part of the team then we don't want to show the snapshot box.
    if (isUserPartOfTeam) {
      return null;
    }
    // If the user user has not yet been logged in no need to show the snapshot box.
    if (
      getLoggedInUserStatus !== "succeeded" ||
      getAllUserStatus !== "succeeded"
    ) {
      return null;
    }

    // if the user is not a member of the team then we show the snapshot box.
    return (
      <div>
        <div className="snapshot-box">
          <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
          <p>
            You haven't joined this team yet. You will be automatically added to
            this team when you complete the survey.
          </p>
        </div>
      </div>
    );
  };

  const getSampleSnapshot = () => {
    if (isSampleAssessment) {
      return (
        <div className="snapshot-box">
          <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
          <p>
            This is a <b>preview</b> of the TEAMscan survey. No answers will be
            saved or seen by anyone in your organization. Only one question from
            each section of the survey will be shown in this preview.
          </p>
        </div>
      );
    }
    return null;
  };

  const getAssessmentHeader = () => {
    if (isSampleAssessment) {
      return (
        <div className="assessment-header">
          <h2 className="mb-0">Preview TEAMscan</h2>

          <Button
            onClick={onClose}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
          >
            <FontAwesomeIcon icon="xmark" className="me-2" /> Exit Preview
          </Button>
        </div>
      );
    }

    return (
      <div className="assessment-header">
        {state === "Overview" ? (
          <div className="row-gap-12px align-items-center">
            <img
              alt={AssessmentMap[1].name}
              src={AssessmentMap[1].assessmentIcon}
            />
            <h2 className="mb-0">TEAMscan</h2>
          </div>
        ) : (
          <h2 className="mb-0">TEAMscan</h2>
        )}

        {/* If the user is not logged in then we should hide this button */}

        {getLoggedInUserStatus === "succeeded" || state === "Success!" ? (
          <Button
            onClick={onClose}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
            xIcon
          />
        ) : null}
      </div>
    );
  };

  const getActionButtons = () => {
    if (
      state === "Assessment" ||
      state === "Overview" ||
      state === "Success!"
    ) {
      return null;
    }

    if (activeFactor === "Target" && isSampleAssessment) {
      return (
        <div className="responsive-button-footer">
          <div className="ms-auto">
            <Button
              size="lg"
              onClick={handleNextClick}
              disabled={isNextDisabled()}
            >
              {getNextButtonText()}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="responsive-button-footer">
        <div>
          <Button
            onClick={handleBackButton}
            variant="secondary-gray"
            size="lg"
            className="border-0"
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            size="lg"
            onClick={handleNextClick}
            disabled={isNextDisabled()}
          >
            {getNextButtonText()}
          </Button>
        </div>
      </div>
    );
  };

  const getAssessmentInformation = () => (
    <div className="column-gap-20px">
      {getAssessmentHeader()}
      {getAutoJoinTeamSnapshot()}
      {getSampleSnapshot()}
      <div className="column-gap-20px">
        {getProgressBar()}
        {getBodyContent()}
        {getActionButtons()}
      </div>
    </div>
  );

  const getAssessmentContent = () => {
    if (!getSurveyScanStatusError) {
      return getAssessmentInformation();
    }
    const onGoHome = () => {
      // if user is logged in then user navigate()
      // otherwise use href.window =
      window.location.href = "/";
    };

    switch (getSurveyScanStatusError) {
      case "invalidSurvey":
        return (
          <div className="empty-survey-card-content">
            <img
              src={NoResultsIcon}
              alt="no results"
              height="80px"
              width="80px"
            />
            <h1>Invalid Event ID</h1>
            <p className="empty-survey-card-content__text">
              The assessment event ID submitted is not a valid event ID.
            </p>
            <Button
              onClick={() => {
                onGoHome();
              }}
              variant="secondary-blue"
            >
              Back to Develop by Criteria
            </Button>
          </div>
        );
      case "completed":
        return (
          <div className="empty-survey-card-content">
            <img
              src={NoResultsIcon}
              alt="no results"
              height="80px"
              width="80px"
            />
            <h1>You already taken this survey.</h1>
            <p className="empty-survey-card-content__text">
              You’ve already completed the TEAMscan survey for this team. Please
              contact your team leader if this is a mistake.
            </p>
            <Button
              onClick={() => {
                onGoHome();
              }}
              variant="secondary-blue"
            >
              Back to Develop by Criteria
            </Button>
          </div>
        );
      case "pastDue":
        return (
          <div className="empty-survey-card-content">
            <img
              src={NoResultsIcon}
              alt="no results"
              height="80px"
              width="80px"
            />
            <h1>This survey is no longer available.</h1>
            <p className="empty-survey-card-content__text">
              The survey period for this TEAMscan survey has already passed.
              Please contact your team leader to extend the survey period.
            </p>
            <Button
              onClick={() => {
                onGoHome();
              }}
              variant="secondary-blue"
            >
              Back to Develop by Criteria
            </Button>
          </div>
        );
    }
    return null;
  };

  if (getSurveyScanStatusStatus === "loading") {
    return <Loading />;
  }

  return getAssessmentContent();
}
