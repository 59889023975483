import { useMemo, useState } from "react";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectCurrentUserAccountId,
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import { sendReminder } from "../slice";
import AvatarCircle from "app/components/AvatarCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDaysRemaining } from "app/containers/Assessment/helpers";
import DashboardHelpPopover from "../DashboardOnboarding/DashboardHelpPopover";
import { updateTeam360 } from "app/containers/AdminConsole/slice";
import { getWeekFromEndDateOrFromNow } from "../helpers";
import {
  selectTeam360ActionByTeamId,
  setTeam360ActionByTeamId,
} from "app/components/Team360Assessment/slice";
import { selectTeamsMostRecentAssessmentsInstancesById } from "app/containers/Assessment/slice";

interface Props {
  teamId: number;
  eventId?: string | null;
  hasDepartmentLeadAccess?: boolean;
}

// TODO:
// 1. If the assessment is overdue and does not have any results avaiable then show 'No Results Available' text instead of 'Results Pending'
// 2. If the results are still pending then the popup show show 'TEXT1' and have a button to remind users to complete the assessment. + Button to 'Manage Surveys'
// 3. If the results are overdue then the popup should show 'TEXT2' and have a button to extend the expiration date by one week from today. + Button to 'Manage Surveys'
// 4. If the results are expired and the user does not have access to edit then we should return null
export default function SmallTeam360Card({
  teamId,
  eventId,
  hasDepartmentLeadAccess,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const teamsByTeamId = useAppSelector(selectTeamsByTeamId);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const team360ActionTaken = useAppSelector(
    selectTeam360ActionByTeamId(teamId)
  );
  const teamsMostRecentAssessment = useAppSelector((state) =>
    selectTeamsMostRecentAssessmentsInstancesById(state, teamId)
  );
  const [reminderClicked, setReminderClicked] = useState(false);

  const { teamName, profilePicture, avatarColor } = teamsByTeamId[teamId] ?? {};
  const hasOverview = !!teamsMostRecentAssessment?.hasEnoughResults;

  const daysRemaining = getDaysRemaining(teamsMostRecentAssessment?.endDate);

  const isOverdue = daysRemaining !== null && daysRemaining < 0;
  const showDaysRemaining = daysRemaining !== null && daysRemaining > 0;
  const totalCompleted = teamsMostRecentAssessment?.totalCompleted ?? 0;
  const totalInvited = teamsMostRecentAssessment?.totalInvited ?? 0;
  const completionText = `${totalCompleted} of ${totalInvited} invited members completed`;

  // This is true if the user is an admin or if the user is a leader of the team
  const userHasAccessToEditTeam360 = useMemo(() => {
    return !!(
      isAdmin ||
      hasDepartmentLeadAccess ||
      (currentUserAccountId &&
        teamsByTeamId?.[teamId]?.teamLeadUserAccountIds?.includes(
          currentUserAccountId
        ))
    );
  }, [
    isAdmin,
    teamsByTeamId,
    teamId,
    currentUserAccountId,
    hasDepartmentLeadAccess,
  ]);

  const getPopoverTextAndTitle = (): { title: string; text: string } => {
    // If user does not have access to edit TEAMscan, then show the default text
    if (!userHasAccessToEditTeam360) {
      return getDaysRemainingTextAndTitle();
    }

    if (team360ActionTaken === "extended") {
      return {
        title: "Survey extended",
        text: "This survey has been extended for one week. An email has been sent to team members who have not taken the survey yet.",
      };
    }
    if (team360ActionTaken === "reminded") {
      return {
        title: "Reminders sent",
        text: "Reminders have been sent to team members who have not taken the survey yet.",
      };
    }

    // If overdue and no results avaiable then we should send a sepeatete text and the title should be No Results Available

    if (isOverdue) {
      const totalNotCompleted = totalInvited - totalCompleted;
      return {
        title: "Extend survey period",
        text: `This survey is now closed, but ${totalNotCompleted} of your team members have not completed it. Would you like to extend the survey period for another week?`,
      };
    }
    return getDaysRemainingTextAndTitle();
  };

  const getDaysRemainingTextAndTitle = (): { title: string; text: string } => {
    const dayOrDays = daysRemaining === 1 ? "day" : "days";
    const baseText =
      "Results for this team will be be available once at least 3 team members have completed the TEAMscan survey.";
    const text = showDaysRemaining
      ? `${baseText} This survey closes in ${daysRemaining} ${dayOrDays}.`
      : baseText;
    return { text, title: "Results pending" };
  };

  const getPopoverFooter = () => {
    if (
      team360ActionTaken === "extended" ||
      team360ActionTaken === "reminded" ||
      !userHasAccessToEditTeam360
    ) {
      return null;
    }

    if (isOverdue) {
      return (
        <div className="row-gap-16px">
          <Button
            onClick={() => {
              dispatch(
                updateTeam360({
                  teamId,
                  endDate: getWeekFromEndDateOrFromNow(
                    teamsMostRecentAssessment?.endDate
                  ),
                })
              );
              dispatch(
                setTeam360ActionByTeamId({ teamId, action: "extended" })
              );
            }}
          >
            Extend for one week
          </Button>
          <Button
            variant="secondary-blue"
            onClick={() =>
              navigate(
                `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=surveys`
              )
            }
          >
            Manage surveys
          </Button>
        </div>
      );
    }

    return (
      <div className="row-gap-16px">
        <Button
          variant={reminderClicked ? "secondary-gray" : undefined}
          disabled={reminderClicked}
          onClick={() => {
            if (reminderClicked) return;
            setReminderClicked(true);
            dispatch(
              sendReminder({
                reminderType: "remindUserToTakeAssessment",
                teamId,
              })
            );
            dispatch(setTeam360ActionByTeamId({ teamId, action: "reminded" }));
          }}
        >
          {reminderClicked ? "Reminders sent" : "Remind this team"}
        </Button>
        <Button
          variant="secondary-blue"
          onClick={() =>
            navigate(
              `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=surveys`
            )
          }
        >
          Manage surveys
        </Button>
      </div>
    );
  };

  const getActionSection = () => {
    if (eventId && !isOverdue) {
      return (
        <div className="my-team-360-card-action">
          {getDaysRemainingText()}
          <Button
            variant="secondary-blue"
            onClick={() =>
              navigate(`/survey/team360?eventId=${eventId}&teamId=${teamId}`)
            }
            className="border-0 button-with-chevron nowrap"
          >
            Begin survey <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </div>
      );
    }

    if (hasOverview) {
      return (
        <div className="my-team-360-card-action">
          <span className="results-ready-list-span">Results ready</span>
          <Button
            variant="secondary-blue"
            onClick={() => navigate(`/TeamGuide/${teamId}?tab=TEAM+360`)}
            className="border-0 button-with-chevron nowrap"
          >
            View results <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </div>
      );
    }

    const { title, text } = getPopoverTextAndTitle();
    return (
      <div className="my-team-360-card-action row-gap-8px">
        {getDaysRemainingText()}
        <p
          className="row-gap-8px align-items-center"
          style={{
            color: "#86888b",
          }}
        >
          <b>
            {showDaysRemaining ? "Results pending" : "No Results Available"}
          </b>
          <DashboardHelpPopover
            title={title}
            showDismiss={
              team360ActionTaken === "extended" ||
              team360ActionTaken === "reminded" ||
              !userHasAccessToEditTeam360
            }
            bodyText={text}
            popoverFooter={getPopoverFooter()}
          />
        </p>
      </div>
    );
  };

  const getDaysRemainingText = () => {
    if (!isOverdue && !showDaysRemaining) {
      return <span className="days-remaining">Due Today</span>;
    }
    if (!showDaysRemaining) {
      return null;
    }
    const dayOrDays = daysRemaining === 1 ? "day" : "days";
    return (
      <span className="days-remaining">
        {daysRemaining} {dayOrDays} left
      </span>
    );
  };

  return (
    <div className="my-team-360-card">
      <div className="my-team-360-card-action-info">
        <AvatarCircle
          profilePicture={profilePicture}
          avatarColor={avatarColor}
          name={teamName}
          size="small"
        />
        <div className="my-team-360-card-info-text">
          <p>
            <b>{teamName}</b>
          </p>
          <p className="small-body-text">{completionText}</p>
        </div>
      </div>
      {getActionSection()}
    </div>
  );
}
