import goodIcon from "resources/icons/good_active.png";
import inactiveGoodIcon from "resources/icons/good.png";
import excellentIcon from "resources/icons/excellent_active.png";
import inactiveExcellentIcon from "resources/icons/excellent.png";
import veryPoorIcon from "resources/icons/very_poor_active.png";
import inactiveVeryPoorIcon from "resources/icons/very_poor.png";
import poorIcon from "resources/icons/poor_active.png";
import inactivePoorIcon from "resources/icons/poor.png";
import fairIcon from "resources/icons/fair_active.png";
import inactiveFairIcon from "resources/icons/fair.png";

export const SMILEY_LABELS = {
  0: {
    label: "Very Poor",
    activeIcon: veryPoorIcon,
    inactiveIcon: inactiveVeryPoorIcon,
  },
  1: {
    label: "Poor",
    activeIcon: poorIcon,
    inactiveIcon: inactivePoorIcon,
  },
  2: {
    label: "Fair",
    activeIcon: fairIcon,
    inactiveIcon: inactiveFairIcon,
  },
  3: {
    label: "Good",
    activeIcon: goodIcon,
    inactiveIcon: inactiveGoodIcon,
  },
  4: {
    label: "Excellent",
    activeIcon: excellentIcon,
    inactiveIcon: inactiveExcellentIcon,
  },
} as {
  [key: number]: {
    label: string;
    activeIcon: string;
    inactiveIcon: string;
  };
};
