import { useMemo } from "react";
import { SMILEY_LABELS } from "./constants";

interface Props {
  onSelectSmiley: (smileyValue: number) => void;
  smileySelected?: number;
}

export default function SmileyFeedbackForm({
  onSelectSmiley,
  smileySelected,
}: Readonly<Props>) {
  const smileys = useMemo(() => {
    return Object.entries(SMILEY_LABELS).map(([key, value]) => {
      const smileyValue = parseInt(key);
      const isSmileySelected = smileyValue === smileySelected;
      return (
        <img
          src={isSmileySelected ? value.activeIcon : value.inactiveIcon}
          onClick={() => onSelectSmiley(smileyValue)}
          tabIndex={smileyValue}
          alt={value.label}
          className={`smiley-feedback-icon ${
            isSmileySelected ? "" : "in"
          }active`}
        />
      );
    });
  }, [smileySelected, onSelectSmiley]);

  return <>{smileys}</>;
}
