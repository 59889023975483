import { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import {
  selectUserInfoById,
  updateUserById,
  selectUpdateUserByIdStatus,
} from "app/containers/Global/slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";

interface Props {
  readonly showModal: boolean;
  readonly userAccountId: number;
  readonly hideModal: () => void;
}

export default function EditInformationModal({
  hideModal,
  userAccountId,
  showModal,
}: Props) {
  const user = useAppSelector(selectUserInfoById(userAccountId));

  const dispatch = useAppDispatch();

  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    gender: "",
    tmgRoleId: 2 as 1 | 2,
  });

  const [showWarningModal, setShowWarningModal] = useState(false);
  const updateUserInfoStatus = useAppSelector(selectUpdateUserByIdStatus);

  useEffect(() => {
    if (user) {
      setPayload({
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle || "",
        gender: user.gender || "",
        tmgRoleId: user.tmgRoleId,
      });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  const getWarningModalMessage = () => {
    if (payload.tmgRoleId === 2) {
      return "This user will no longer be able to access admin settings, change access permissions, and configure any global organization settings.";
    } else {
      return "Admins have the ability to create/delete teams, invite/remove members, change access permissions, and configure any global organization settings.";
    }
  };

  const getWarningTitle = () => {
    const name = payload.firstName ? payload.firstName : user.emailAddress;
    const type = payload.tmgRoleId === 1 ? "Admin" : "User";

    return `Are you sure you want to switch ${name} to "${type}" ?`;
  };

  const getModalContent = () => (
    <>
      <Form.Group className="form-group">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          placeholder="Stanley"
          required
          value={payload.firstName}
          onChange={(e) => {
            setPayload({
              ...payload,
              firstName: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          placeholder="Hudson"
          required
          value={payload.lastName}
          onChange={(e) => {
            setPayload({
              ...payload,
              lastName: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Job Title</Form.Label>
        <Form.Control
          placeholder="UI/UX Designer"
          value={payload.jobTitle}
          onChange={(e) => {
            setPayload({
              ...payload,
              jobTitle: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Gender</Form.Label>
        <Form.Select
          onChange={(e) => {
            setPayload({
              ...payload,
              gender: e.target.value,
            });
          }}
          value={payload.gender}
        >
          <option value="" disabled={true}>
            Select Gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non-binary">Non-binary</option>
          <option value="do-not-disclose">Choose not to disclose</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Access Role</Form.Label>
        <Form.Select
          onChange={(e) => {
            setPayload({
              ...payload,
              tmgRoleId: parseInt(e.target.value) as 1 | 2,
            });
          }}
          value={payload.tmgRoleId}
        >
          <option value="" disabled={true}>
            Select Access Role
          </option>
          <option value="1">Admin</option>
          <option value="2">User</option>
        </Form.Select>
      </Form.Group>
    </>
  );

  const updateUser = () => {
    dispatch(updateUserById({ userAccountId, payload }));
    hideModal();
    setShowWarningModal(false);
  };

  const handleSubmit = () => {
    const roleIdHasBeenChanged = payload.tmgRoleId !== user.tmgRoleId;
    if (roleIdHasBeenChanged) {
      setShowWarningModal(true);
    } else {
      updateUser();
    }
  };

  if (updateUserInfoStatus === "loading" && !showWarningModal) {
    return (
      <Modal
        show={showModal}
        onHide={hideModal}
        className="edit-information-modal"
        size="lg"
      >
        <div className="modal-title-row">
          <h2>Edit User Information</h2>
          <Button
            onClick={hideModal}
            variant={"secondary-blue"}
            style={{ border: "none" }}
            xIcon
          />
        </div>
        <div className="edit-information-modal-body">
          <p>Updating user information...</p>
        </div>
      </Modal>
    );
  }

  if (showWarningModal) {
    return (
      <WarningModal
        modalTitle="Change permissions"
        warningTitle={getWarningTitle()}
        warningMessage={getWarningModalMessage()}
        isOpen={showWarningModal}
        onConfirmClick={updateUser}
        hideModal={() => setShowWarningModal(false)}
        isDanger={false}
        customButtonText="Confirm Changes"
        isInProgress={updateUserInfoStatus === "loading"}
      />
    );
  }

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className="edit-information-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>Edit User Information</h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none" }}
          xIcon
        />
      </div>
      <Form
        className="edit-information-form"
        onSubmit={(e) => {
          handleSubmit();
          e.preventDefault();
        }}
      >
        <div className="edit-information-modal-body">{getModalContent()}</div>
        <div className="edit-information-modal-footer">
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </Modal>
  );
}
