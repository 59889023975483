import {
  createAsyncThunk,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  AssessmentEvent,
  AssessmentInstance,
  AssessmentSeries,
  AllMostRecentInstanceInfo,
  TeamMostRecentInstanceInfo,
  DepartmentMostRecentInstanceInfo,
  MostRecentInstanceInfo,
  PutAssessmentPayload,
  PutAssessmentInstancePayload,
} from "./types";
import {
  assessmentInstanceAdapter,
  assessmentSeriesAdapter,
  teamsMostRecentInstanceAdapter,
  departmentsMostRecentInstanceAdapter,
} from "./adaptors";

// ------------------------------------ State Type/Structure ------------------------------------
export interface AssessmentState {
  pendingAssessments: {
    [eventId: string]: AssessmentEvent;
  };
  getPendingAssessmentsStatus: responseStatus;
  assessmentInstances: EntityState<AssessmentInstance>;
  assessmentSeries: EntityState<AssessmentSeries>;
  teamsMostRecentInstances: EntityState<TeamMostRecentInstanceInfo>;
  departmentsMostRecentInstances: EntityState<DepartmentMostRecentInstanceInfo>;
  companyMostRecentInstance: MostRecentInstanceInfo | null;
  gettingAssessmentInstancesStatus: responseStatus;
  gettingAssessmentSeriesStatus: responseStatus;
  gettingAllMostRecentAssessmentInstancesStatus: responseStatus;
  updatingAssessmentInstanceStatus: responseStatus;
  updatingAssessmentSeriesStatus: responseStatus;
}

// ------------------------------------ InitialState ------------------------------------
const initialState: AssessmentState = {
  pendingAssessments: {},
  getPendingAssessmentsStatus: "idle",
  companyMostRecentInstance: null,
  teamsMostRecentInstances: teamsMostRecentInstanceAdapter.getInitialState(),
  departmentsMostRecentInstances:
    departmentsMostRecentInstanceAdapter.getInitialState(),
  assessmentInstances: assessmentInstanceAdapter.getInitialState(),
  assessmentSeries: assessmentSeriesAdapter.getInitialState(),
  gettingAssessmentInstancesStatus: "idle",
  gettingAssessmentSeriesStatus: "idle",
  gettingAllMostRecentAssessmentInstancesStatus: "idle",
  updatingAssessmentInstanceStatus: "idle",
  updatingAssessmentSeriesStatus: "idle",
};

// ------------------------------------ GETS ------------------------------------
export const getPendingAssessments = createAsyncThunk(
  "assessmentContainer/getPendingAssessments",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/pendingAssessments`;
    const response = (await request(requestUrl)) as AssessmentEvent[];
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        assessment: { getPendingAssessmentsStatus },
      } = getState() as RootState;
      return getPendingAssessmentsStatus === "idle";
    },
  }
);

export const getAssessmentSeries = createAsyncThunk(
  "assessmentContainer/getAssessmentSeries",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/series`;
    const response = (await request(requestUrl)) as AssessmentSeries[];
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        assessment: { gettingAssessmentSeriesStatus },
      } = getState() as RootState;
      return gettingAssessmentSeriesStatus === "idle";
    },
  }
);

export const getAssessmentInstances = createAsyncThunk(
  "assessmentContainer/getAssessmentInstances",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessmentInstances`;
    const response = (await request(requestUrl)) as AssessmentInstance[];
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        assessment: { gettingAssessmentInstancesStatus },
      } = getState() as RootState;
      return gettingAssessmentInstancesStatus === "idle";
    },
  }
);

export const getMostRecentAssessmentInstances = createAsyncThunk(
  "assessmentContainer/getMostRecentAssessmentInstances",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/mostRecentAssessments`;
    const response = (await request(requestUrl)) as AllMostRecentInstanceInfo;
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        assessment: { gettingAllMostRecentAssessmentInstancesStatus },
      } = getState() as RootState;
      return gettingAllMostRecentAssessmentInstancesStatus === "idle";
    },
  }
);

// ------------------------------------ POSTS ------------------------------------
// ------------------------------------ PUTS ------------------------------------
export const updateAssessmentInstance = createAsyncThunk(
  "assessmentContainer/updateAssessmentInstance",
  async ({
    payload,
    assessmentInstanceId,
  }: {
    payload: PutAssessmentInstancePayload;
    assessmentInstanceId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessmentInstances/${assessmentInstanceId}`;
    const response = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as AssessmentInstance;
    return response;
  }
);

export const updateAssessmentSeries = createAsyncThunk(
  "assessmentContainer/updateAssessmentSeries",
  async ({
    payload,
    assessmentId,
  }: {
    payload: PutAssessmentPayload & { stopRecurringDate?: string };
    assessmentId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/series/${assessmentId}`;
    const response = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as AssessmentSeries;
    return response;
  }
);

// ------------------------------------ DELETES ------------------------------------

// ------------------------------------ Beginning of Slice Definition ------------------------------------
export const assessmentSlice = createSlice({
  name: "assessmentContainer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingAssessments.pending, (state) => {
        state.getPendingAssessmentsStatus = "loading";
      })
      .addCase(
        getPendingAssessments.fulfilled,
        (state, { payload }: PayloadAction<AssessmentEvent[]>) => {
          const pendingAssessments: {
            [eventId: string]: AssessmentEvent;
          } = {};
          payload.forEach((assessmentEvent) => {
            if (!assessmentEvent.eventId) {
              return;
            }
            pendingAssessments[assessmentEvent.eventId] = assessmentEvent;
          });

          return {
            ...state,
            getPendingAssessmentsStatus: "succeeded",
            pendingAssessments,
          };
        }
      )
      .addCase(getPendingAssessments.rejected, (state) => {
        state.getPendingAssessmentsStatus = "failed";
      })
      .addCase(getAssessmentInstances.rejected, (state) => {
        state.gettingAssessmentInstancesStatus = "failed";
      })
      .addCase(getAssessmentInstances.pending, (state) => {
        state.gettingAssessmentInstancesStatus = "loading";
      })
      .addCase(
        getAssessmentInstances.fulfilled,
        (state, { payload }: PayloadAction<AssessmentInstance[]>) => {
          assessmentInstanceAdapter.setAll(state.assessmentInstances, payload);
          state.gettingAssessmentInstancesStatus = "succeeded";
        }
      )
      .addCase(getAssessmentSeries.rejected, (state) => {
        state.gettingAssessmentSeriesStatus = "failed";
      })
      .addCase(getAssessmentSeries.pending, (state) => {
        state.gettingAssessmentSeriesStatus = "loading";
      })
      .addCase(
        getAssessmentSeries.fulfilled,
        (state, { payload }: PayloadAction<AssessmentSeries[]>) => {
          state.gettingAssessmentSeriesStatus = "succeeded";
          assessmentSeriesAdapter.setAll(state.assessmentSeries, payload);
        }
      )
      .addCase(getMostRecentAssessmentInstances.rejected, (state) => {
        state.gettingAllMostRecentAssessmentInstancesStatus = "failed";
      })
      .addCase(getMostRecentAssessmentInstances.pending, (state) => {
        state.gettingAllMostRecentAssessmentInstancesStatus = "loading";
      })
      .addCase(
        getMostRecentAssessmentInstances.fulfilled,
        (state, { payload }: PayloadAction<AllMostRecentInstanceInfo>) => {
          state.gettingAllMostRecentAssessmentInstancesStatus = "succeeded";
          state.companyMostRecentInstance = payload?.company;
          teamsMostRecentInstanceAdapter.setAll(
            state.teamsMostRecentInstances,
            payload.teams
          );
          departmentsMostRecentInstanceAdapter.setAll(
            state.departmentsMostRecentInstances,
            payload.departments
          );
        }
      )
      .addCase(updateAssessmentSeries.rejected, (state) => {
        state.updatingAssessmentSeriesStatus = "failed";
      })
      .addCase(updateAssessmentSeries.fulfilled, (state, { payload }) => {
        state.updatingAssessmentSeriesStatus = "succeeded";
        assessmentSeriesAdapter.upsertOne(state.assessmentSeries, payload);
      })
      .addCase(updateAssessmentSeries.pending, (state) => {
        state.updatingAssessmentSeriesStatus = "loading";
      })
      .addCase(updateAssessmentInstance.rejected, (state) => {
        state.updatingAssessmentInstanceStatus = "failed";
      })
      .addCase(updateAssessmentInstance.fulfilled, (state, { payload }) => {
        state.updatingAssessmentInstanceStatus = "succeeded";
        assessmentInstanceAdapter.upsertOne(state.assessmentInstances, payload);
      })
      .addCase(updateAssessmentInstance.pending, (state) => {
        state.updatingAssessmentInstanceStatus = "loading";
      });
  },
});

// ------------------------------------ Selectors ------------------------------------
export const selectPendingAssessments = (state: RootState) =>
  state.assessment.pendingAssessments;
export const selectGetPendingAssessmentsStatus = (state: RootState) =>
  state.assessment.getPendingAssessmentsStatus;
export const {
  selectById: selectTeamAssessmentsInstancesById,
  selectAll: selectAllTeamAssessmentsInstances,
  selectEntities: selectTeamAssessmentsInstancesEntities,
} = assessmentInstanceAdapter.getSelectors<RootState>(
  (state) => state.assessment.assessmentInstances
);

export const {
  selectById: selectTeamAssessmentsSeriesById,
  selectAll: selectAllTeamAssessmentsSeries,
  selectEntities: selectTeamAssessmentsSeriesEntities,
} = assessmentSeriesAdapter.getSelectors<RootState>(
  (state) => state.assessment.assessmentSeries
);

export const {
  selectById: selectTeamsMostRecentAssessmentsInstancesById,
  selectAll: selectAllTeamsMostRecentAssessmentsInstances,
  selectEntities: selectTeamsMostRecentAssessmentsInstancesEntities,
} = teamsMostRecentInstanceAdapter.getSelectors<RootState>(
  (state) => state.assessment.teamsMostRecentInstances
);
export const {
  selectById: selectDepartmentsMostRecentAssessmentsInstancesById,
  selectAll: selectAllDepartmentsMostRecentAssessmentsInstances,
  selectEntities: selectDepartmentsMostRecentAssessmentsInstancesEntities,
} = departmentsMostRecentInstanceAdapter.getSelectors<RootState>(
  (state) => state.assessment.departmentsMostRecentInstances
);

export const selectMostRecentCompanyAssessmentInstance = (state: RootState) =>
  state.assessment.companyMostRecentInstance;

export const selectGettingAllMostRecentAssessmentInstancesStatus = (
  state: RootState
) => state.assessment.gettingAllMostRecentAssessmentInstancesStatus;
export const selectGettingAssessmentInstancesStatus = (state: RootState) =>
  state.assessment.gettingAssessmentInstancesStatus;
export const selectGettingAssessmentSeriesStatus = (state: RootState) =>
  state.assessment.gettingAssessmentSeriesStatus;
export const selectUpdatingAssessmentInstanceStatus = (state: RootState) =>
  state.assessment.updatingAssessmentInstanceStatus;
export const selectUpdatingAssessmentSeriesStatus = (state: RootState) =>
  state.assessment.updatingAssessmentSeriesStatus;

export default assessmentSlice.reducer;
