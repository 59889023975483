/**
 *
 * GlobalNavBar
 *
 */
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import PlaceHolderIcon from "resources/icons/logo-criteria-dark.svg";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectGetUserStatus,
  selectCurrentUserAccountId,
  selectCompanyInfo,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import AvatarCircle from "app/components/AvatarCircle";
import Select, { SingleValue } from "react-select";
import { SearchHistoryItem, SearchOption } from "./types";
import { getSelectProps, logUserOut } from "utils/helperFunctions";
import { ReactElement, ReactNode, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import { GuideType } from "app/components/Modules/types";
import NotificationBellWithNumber from "app/storybookComponents/NotificationBellWithNumber";
import NotificationCard from "app/containers/Dashboard/Cards/NotificationCard";
import {
  selectAllPendingAssessmentsNotifications,
  selectAllTeamInvitations,
} from "app/containers/Dashboard/slice";
import { REACT_APP_HIRESELECT_URL } from "utils/environmentVariables";
import useWidthBasedVisibility from "utils/hooks/useWidthBasedVisibility";

export default function GlobalNavBar() {
  const navigate = useNavigate();
  // ----------------------------- App Selectors ---------------------------------------- //
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const companyGuide = useAppSelector(selectCompanyGuide);
  const getUserStatus = useAppSelector(selectGetUserStatus);
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);

  // ----------------------------- use State ---------------------------------------- //
  const [isNotificationsOpened, setIsNotificationsOpened] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [forceShowSearchBar, setForceShowSearchBar] = useState(false);
  const isHamburgerHidden = useWidthBasedVisibility(768); // isHamburgerHidden
  const isGreaterThan620 = useWidthBasedVisibility(620);
  const isGreaterThan1000 = useWidthBasedVisibility(1000);
  const isGreaterThan1200 = useWidthBasedVisibility(1200);

  // ----------------------------- refs ---------------------------------------- //
  const selectRef = useRef<any>(null);

  // ----------------------------- memo constants ---------------------------------------- //
  // We shall use this information to determine if the button to pre-hire should be shown or not.
  const userInformation = useMemo(
    () => (userAccountId ? usersById[userAccountId] : undefined),
    [userAccountId, usersById]
  );

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "logout": {
        return logUserOut();
      }
      case "criteria-platform": {
        window.location.href = `${REACT_APP_HIRESELECT_URL}`;
        return;
      }
      case "userGuide": {
        return navigate("/UserGuide");
      }
      case "userSettings": {
        return navigate("/UserSettings");
      }
    }
  };

  const getNoSearchOptions = () => [
    { label: "Browse all teams", value: "all teams", isSearchOption: true },
    {
      label: "Browse all people",
      value: "all users",
      isSearchOption: true,
    },
    {
      label: "Browse all departments",
      value: "all departments",
      isSearchOption: true,
    },
    ...getHistoryOptions(),
  ];

  const getAllSearchOptions = (): SearchOption[] => {
    if (!inputValue) {
      return getNoSearchOptions();
    }

    const searchOptions: SearchOption[] = [];
    // We need to only return back 5 items
    if (
      companyInfo?.companyName?.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      const companyOption = getCompanyOption();
      if (companyOption) searchOptions.push(companyOption);
    }

    Object.values(teamByTeamId).forEach((team) => {
      if (team.teamName.toLowerCase().includes(inputValue.toLowerCase())) {
        const teamOption = getTeamOption(team.teamId);
        if (teamOption) searchOptions.push(teamOption);
      }
    });

    Object.values(usersById).forEach((user) => {
      if (
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      ) {
        const userOption = getUserOption(user.userAccountId);
        if (userOption) {
          searchOptions.push(userOption);
        }
      }
    });

    if (searchOptions.length > 5) {
      searchOptions.splice(5);
      searchOptions.push({
        label: "Search for " + inputValue + " in all teams",
        value: inputValue,
        isSearchOption: true,
        onClick: () => navigate(`/Search/Teams?searchInput=${inputValue}`),
      });
      searchOptions.push({
        label: "Search for " + inputValue + " in all people",
        value: inputValue,
        isSearchOption: true,
        onClick: () => navigate(`/Search/People?searchInput=${inputValue}`),
      });
      searchOptions.push({
        label: "Search for " + inputValue + " in all departments",
        value: inputValue,
        isSearchOption: true,
        onClick: () =>
          navigate(`/Search/Departments?searchInput=${inputValue}`),
      });
    }

    return searchOptions;
  };

  const getTeamOption = (teamId: number) => {
    if (!teamByTeamId[teamId]) return null;

    const team = teamByTeamId[teamId];
    return {
      label: team.teamName,
      value: String(team.teamId),
      avatarCircle: (
        <AvatarCircle
          name={team.teamName}
          profilePicture={team.profilePicture}
          avatarColor={team.avatarColor}
          size="small"
        />
      ),
      guideType: "team" as GuideType,
    };
  };

  const getUserOption = (userAccountId: number) => {
    if (!usersById[userAccountId]) return null;

    const user = usersById[userAccountId];
    return {
      label: `${user.firstName} ${user.lastName}`,
      value: String(user.userAccountId),
      avatarCircle: (
        <AvatarCircle
          name={`${user.firstName} ${user.lastName}`}
          userAccountId={user.userAccountId}
          size="small"
        />
      ),
      guideType: "user" as GuideType,
    };
  };

  const getCompanyOption = () => {
    if (!companyInfo) return null;
    return {
      label: companyInfo.companyName,
      value: String(companyInfo.companyAccountId),
      avatarCircle: (
        <AvatarCircle
          name={companyInfo.companyName}
          size={"small"}
          profilePicture={companyGuide?.profilePicture?.picture}
        />
      ),
      guideType: "company" as GuideType,
    };
  };

  const getHistoryOptions = (): SearchOption[] => {
    const searchHistory = localStorage.getItem("searchHistory");
    if (!searchHistory) {
      return [];
    }
    const historyArray = JSON.parse(searchHistory);
    const returnArr: SearchOption[] = [];
    historyArray.forEach((historyItem: SearchHistoryItem) => {
      const option = getHistoryOption(
        historyItem.label,
        historyItem.value,
        historyItem.guideType
      );
      if (option) returnArr.push(option);
    });
    return returnArr;
  };

  const getHistoryOption = (
    label: string,
    value: string = "",
    guideType?: GuideType
  ) => {
    switch (guideType) {
      case "company": {
        return getCompanyOption();
      }
      case "team": {
        return getTeamOption(Number(value));
      }
      case "user": {
        return getUserOption(Number(value));
      }
      default: {
        return {
          label,
          value,
        };
      }
    }
  };

  const onGetMyTeams = () => {
    if (!userAccountId) return [];
    const myTeams: ReactElement[] = [];
    usersById?.[userAccountId]?.teamIds?.forEach((teamId) => {
      if (teamByTeamId[teamId])
        myTeams.push(
          <NavDropdown.Item
            as={NavLink}
            to={`/TeamGuide/${teamId}`}
            key={teamId}
          >
            <AvatarCircle
              size="small"
              name={teamByTeamId[teamId].teamName}
              profilePicture={teamByTeamId[teamId].profilePicture}
              avatarColor={teamByTeamId[teamId].avatarColor}
            />
            {teamByTeamId[teamId].teamName}
          </NavDropdown.Item>
        );
    });

    if (myTeams.length === 0) return null;
    return (
      <>
        <span>My Teams</span>
        {myTeams}
      </>
    );
  };

  const onSelectChange = (newValue: SingleValue<SearchOption>) => {
    if (newValue) {
      updateHistory(newValue);
    }
    if (newValue?.value === "all teams") return navigate("/Search/Teams");
    if (newValue?.value === "all users") return navigate("/Search/People");
    if (newValue?.value === "all departments")
      return navigate("/Search/Departments");
    if (newValue?.guideType === "company") {
      return navigate(`/OrganizationGuide`);
    }
    if (newValue?.guideType === "team") {
      return navigate(`/TeamGuide/${newValue?.value}`);
    }
    if (newValue?.guideType === "user") {
      return navigate(`/UserGuide/${newValue?.value}`);
    }
    newValue?.onClick?.();
    // If they made it all the way down here then they clicked on "Search for <search term>"
  };

  // When a new value is selected, first we check if that value exist inside of our local storage history.
  // If it does not exist then we add it to the local storage history.
  // If it does exist then we move it to the top of the local storage history.
  // If the local storage history is greater than 5 then we remove the last item in the local storage history.
  // If the local storage history is less than 5 then we add the new value to the local storage history.
  const updateHistory = (searchOption: SearchOption) => {
    const { label, value, guideType, isSearchOption } = searchOption;
    if (isSearchOption) return;
    const history = localStorage.getItem("searchHistory");
    if (!history) {
      localStorage.setItem(
        "searchHistory",
        JSON.stringify([{ label, value, guideType }])
      );
      return;
    }

    const historyArray = JSON.parse(history);
    const index = historyArray.findIndex(
      (item: SearchHistoryItem) =>
        item.label === label &&
        item.value === value &&
        item.guideType === guideType
    );

    if (index > -1) {
      historyArray.splice(index, 1);
    }

    historyArray.unshift({ label, value, guideType });

    if (historyArray.length > 5) {
      historyArray.pop();
    }

    localStorage.setItem("searchHistory", JSON.stringify(historyArray));
  };

  const getMemberOption = (
    memberInfo: {
      label: string;
      avatarCircle?: ReactNode;
    } | null
  ) => {
    if (!memberInfo) return null;
    const { label, avatarCircle } = memberInfo;
    if (!avatarCircle) {
      return (
        <div
          style={{
            padding: "4px",
            fontSize: "14px",
          }}
        >
          <span>{label}</span>
        </div>
      );
    }

    return (
      <div className="member-option" style={{ alignItems: "center" }}>
        {avatarCircle}
        <div className="member-info">
          <span className="member-name">{label}</span>
        </div>
      </div>
    );
  };

  const getNotificationCount = () => {
    const teamInvitations: {
      [teamId: number]: true;
    } = {};
    const assessmentInvitations: {
      [assessmentId: number]: true;
    } = {};
    allPendingAssessmentNotifications?.forEach((notification) => {
      if (notification.teamId)
        assessmentInvitations[notification.teamId] = true;
    });
    allPendingTeamNotifications?.forEach((notification) => {
      if (notification.teamId) teamInvitations[notification.teamId] = true;
    });
    return (
      Object.keys(teamInvitations).length +
      Object.keys(assessmentInvitations).length
    );
  };

  const getSearchBar = () => (
    <Select<SearchOption>
      placeholder="Search teams and people..."
      options={options}
      components={components}
      value={null}
      formatOptionLabel={getMemberOption}
      styles={selectStyles}
      onChange={onSelectChange}
      filterOption={() => true}
      inputValue={inputValue}
      onInputChange={setInputValue}
      isClearable
      ref={selectRef}
    />
  );

  const getSearchElement = () => {
    // if greater than 1200 then show the search bar
    if (isGreaterThan1200) return getSearchBar();
    if (forceShowSearchBar) {
      return (
        <div className="row-gap-8px align-items-center">
          {getSearchBar()}
          <div role="button" onClick={() => setForceShowSearchBar(false)}>
            <FontAwesomeIcon icon="times" />
          </div>
        </div>
      );
    }

    return (
      <div role="button" onClick={() => setForceShowSearchBar(true)}>
        <FontAwesomeIcon icon="search" />
      </div>
    );
  };

  const getGuidesDropdown = () => (
    <NavDropdown
      title="Guides"
      id="collasible-nav-dropdown"
      className="guide-dropdown"
    >
      <span>Organization</span>
      <NavDropdown.Item as={NavLink} to="/OrganizationGuide">
        {companyOptionInfo?.avatarCircle}
        {companyOptionInfo?.label}
      </NavDropdown.Item>
      {onGetMyTeams()}
      <span>My Guide</span>
      <NavDropdown.Item as={NavLink} to="/UserGuide">
        <AvatarCircle size="small" userAccountId={userAccountId || undefined} />
        {user?.firstName} {user?.lastName}
      </NavDropdown.Item>
    </NavDropdown>
  );

  const getButtonToPreHire = () => {
    // if the user does not have access to the pre-hire then we should return null.
    if (!userInformation?.hasAccessToPreHire) return null;
    // if the searchbar is showing and the greater than 1000 is false then we should return null.
    if (forceShowSearchBar && !isGreaterThan1000) return null;
    return (
      <>
        <div className="nav-bar-vertical-line" />
        <a href={`${REACT_APP_HIRESELECT_URL}`} className="nav-link">
          Hire
          <FontAwesomeIcon icon="external-link" className="ms-2" size="xs" />
        </a>
      </>
    );
  };

  const getUserNavigationCollapse = () => (
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link as={NavLink} to="/">
          Home
        </Nav.Link>
        {getGuidesDropdown()}
        <Nav.Link as={NavLink} to="/SkillsGuide">
          Library
        </Nav.Link>
        {isAdmin ? (
          <Nav.Link as={NavLink} to="/AdminConsole?tab=People">
            Admin Console
          </Nav.Link>
        ) : null}
        {/* Only when the hamburger is hidden is when we show the nav items inside of the actual navbar */}
        {!isHamburgerHidden ? (
          <>
            <Nav.Link as={NavLink} to="/UserGuide">
              My User Guide
            </Nav.Link>
            <Nav.Link as={NavLink} to="/UserSettings">
              User Settings
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/"
              active={false}
              onClick={() => {
                logUserOut();
              }}
              className="not-active"
            >
              Log Out
            </Nav.Link>
          </>
        ) : null}
        {getButtonToPreHire()}
      </Nav>
    </Navbar.Collapse>
  );

  const getAvatarDropdown = () => {
    if (!userAccountId) return null;
    if (!isHamburgerHidden) return null;
    return (
      <NavDropdown
        title={
          user ? (
            <AvatarCircle
              userAccountId={userAccountId || undefined}
              profilePicture={user.profilePicture}
              avatarColor={user.avatarColor}
            />
          ) : null
        }
        id="user-settings"
        onSelect={onDropdownSelect}
      >
        <Dropdown.Item eventKey="userGuide">My User Guide</Dropdown.Item>
        <Dropdown.Item eventKey="userSettings">User Settings</Dropdown.Item>
        {userInformation?.hasAccessToPreHire ? (
          <Dropdown.Item eventKey="criteria-platform">
            Hire by Criteria
            <FontAwesomeIcon icon="external-link" className="ms-2" />
          </Dropdown.Item>
        ) : null}
        <Dropdown.Item eventKey="logout">Logout</Dropdown.Item>
      </NavDropdown>
    );
  };

  const getRightGroupedElements = () => (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
      className="row-gap-20px"
    >
      {getSearchElement()}
      <NavDropdown
        title={
          <NotificationBellWithNumber
            hasPendingNotifications={
              !!(
                allPendingTeamNotifications?.length ||
                allPendingAssessmentNotifications?.length
              )
            }
            numberCount={getNotificationCount()}
            color="#202D63"
          />
        }
        id="notification-dropdown"
        show={isNotificationsOpened}
        onToggle={() => setIsNotificationsOpened(!isNotificationsOpened)}
      >
        <NotificationCard />
      </NavDropdown>
      {getAvatarDropdown()}
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="navbar-toggle"
      />
    </div>
  );

  const getUserNavigation = () => {
    if (getUserStatus !== "succeeded") {
      return null;
    }

    if (isHamburgerHidden) {
      return (
        <>
          {getUserNavigationCollapse()}
          {getRightGroupedElements()}
        </>
      );
    }

    // When collapsed we need to reverse the order of the elements
    return (
      <>
        {getRightGroupedElements()}
        {getUserNavigationCollapse()}
      </>
    );
  };

  const getCompanyLogo = () => {
    // if force the search bar to show, the hamburger is showing, and the screen is smaller than 620 then we need to hide the logo
    if (forceShowSearchBar && !isHamburgerHidden && !isGreaterThan620) {
      return <div />;
    }

    return (
      <Navbar.Brand as={NavLink} to="/">
        <img
          src={PlaceHolderIcon}
          alt="Criteria"
          style={{ width: "110px", height: "27px" }}
        />
      </Navbar.Brand>
    );
  };

  const { selectStyles, components } = getSelectProps("search", {
    fixedWidth: 300,
    controlBgColor: "#eceef9",
  });

  const user = (userAccountId && usersById[userAccountId]) || undefined;
  const options = getAllSearchOptions();
  const companyOptionInfo = getCompanyOption();
  return (
    <Navbar
      collapseOnSelect
      expand={isHamburgerHidden}
      className="global-nav-bar"
      variant="dark"
    >
      {getCompanyLogo()}
      {getUserNavigation()}
    </Navbar>
  );
}
